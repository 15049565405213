.sticky-cell-header {
  position: sticky;
  left: 0;
  z-index: 11 !important;
  background-color: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.sticky-cell-header:hover,
.sticky-row-header-hover-highlight {
  background-color: #fff;
}

.sticky-cell {
  position: sticky;
  left: 0;
  z-index: 10 !important;
  background-color: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.sticky-cell:hover,
.sticky-row-hover-highlight {
  background-color: #f5f5f5;
}

.shadow-right {
  box-shadow: 0px 0 5px -2px #888;
  clip-path: inset(0px -15px 0px 0px);
}

.scrollbar {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}
.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 9px;
  border-style: none;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.scrollbar::-webkit-scrollbar:horizontal {
  width: 9px;
  height: 4px;
  border-style: none;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaaaaa99 !important;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
  width: 0;
  height: 0;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Adjust thumb color when hovering  */
}
