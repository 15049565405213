.sticky-cell-header {
  position: sticky;
  left: 0;
  z-index: 11 !important;
  background-color: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.sticky-cell-header:hover,
.sticky-row-header-hover-highlight {
  background-color: #fff;
}

.sticky-cell {
  position: sticky;
  left: 0;
  z-index: 10 !important;
  background-color: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.sticky-cell:hover,
.sticky-row-hover-highlight {
  background-color: #f5f5f5;
}

.shadow-right {
  box-shadow: 0px 0 5px -2px #888;
  clip-path: inset(0px -15px 0px 0px);
}
