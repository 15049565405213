.container {
  -ms-overflow-y-style: auto; /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
}
/*  Safari and Chrome */

.container::-webkit-scrollbar {
  width: 0px;
  height: 2px;
  border-style: none;
}

.container::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

.container::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 74%);
  height: 2px;
}
