@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  ol {
    @apply list-decimal;
    @apply pl-[40px];
  }

  ul {
    @apply list-disc;
    @apply pl-[40px];
  }
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  height: 100%;
  width: 100%;
}

.Toastify .Toastify__toast-container {
  width: max-content;
  /* left: 50% !important; */
  /* transform: translateX(-50%) !important; */
}

.Toastify .Toastify__toast {
  padding: 0px;
  min-height: fit-content;
  border-radius: 12px;
}

.Toastify .Toastify__toast-body {
  padding: 0px;
  border-radius: 12px;
}

.scrollbar {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}
.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 9px;
  border-style: none;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaaaaa99 !important;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-corner {
  background: transparent;
  width: 0;
  height: 0;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Adjust thumb color when hovering  */
}
/* global.css */

.trash-icon {
  transition: stroke 0.3s ease;
}

.trash-icon:hover path {
  stroke: rgba(255, 0, 0, 0.75);
}

.delete-icon:hover path {
  fill: rgba(255, 0, 0, 0.75);
}
