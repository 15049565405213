.overflow-tooltip-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflowed {
  overflow: visible;
}
